html {
  max-width: 100vw;
  overflow-x: hidden;
  width: 100%;

  body {
    @include reset-margin-paddding;
    box-sizing: border-box;
    font-family: sans-serif;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    div,
    header,
    section {
      @include reset-margin-paddding;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include font-inherit;
      @include reset-margin-paddding;
    }

    p {
      @include reset-margin-paddding;
    }

    ul {
      @include reset-margin-paddding;
      list-style: none;

      li {
        @include reset-margin-paddding;
        list-style: none;
      }
    }

    a {
      @include font-inherit;
      text-decoration: none;
    }

    button,
    input[type=submit] {
      @include font-inherit;
      @include reset-margin-paddding;
      appearance: button;
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      overflow: visible;
      text-transform: none;
    }

    input[type=text] {
      @include reset-margin-paddding;
      white-space: normal;
      background: transparent;
      line-height: inherit;
      font-size: inherit;
      color: inherit;
      font-family: inherit;
    }
  }
}
