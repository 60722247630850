#footer {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(40px, 180px);
  @include source-sans-pro-regular(18px);
  background-color: rgba($light-grayish-violet, 0.5);
  color: $very-dark-grayish-blue;

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414}px);
  }

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(#{$outer-1024 / 2}px);
    @include padding-top-bottom(58px, 68px);
    @include source-sans-pro-regular(20px);
    display: flex;
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(#{$outer-1440} / 2px);
    @include padding-top-bottom(80px, 96px);
    @include source-sans-pro-regular(28px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(#{$outer-1600 / 2}px);
    @include padding-top-bottom(108px, 128px);
    @include source-sans-pro-regular(36px);
  }

  .footer-column {
    @include margin-bottom-last-child-0(36px);

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(0);
      @include margin-bottom-last-child-0(40px);
      @include padding-left-right(#{$outer-1024 / 2}px);
      flex-basis: 33.33%;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(58px);
      @include padding-left-right(#{$outer-1440 / 2}px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(76px);
      @include padding-left-right(#{$outer-1600 / 2}px);
    }

    .column-item {
      @include margin-bottom-last-child-0(36px);

      .couse-name,
      .teacher-name,
      .newsletter-title,
      .scaleclock-title {
        @include source-sans-pro-bold(18px);

        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(20px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(28px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(36px);
        }
      }

      .newsletter-link,
      .download-app {
        @include source-sans-pro-bold(18px);
        color: $very-light-violet;
        display: inline-block;
        margin-top: 20px;
      }

      .newsletter-intro {
        max-width: 200px;

        @media (min-width: #{$ipad-landscape-width}px) {
          max-width: initial;
        }
      }
    }
  }

  .teacher-informations {
    .social-list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin-top: 20px;

      .social-item {
        @include margin-right-last-child-0(8px);

        .social-link {
          @include same-min-max-width(40px);
          display: block;

          .link-icon {
            display: block;
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }
}
