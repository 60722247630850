#page-cover {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  min-height: 50vw;
  position: relative;
  text-align: center;
  width: 100%;

  @media (min-width: #{$ipad-landscape-width}px) {
    min-height: 28vw;
  }

  .cover-title {
    @include din-condensed-bold(60px, 1);
    color: $white;
    display: block;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include din-condensed-bold(100px, 1);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include din-condensed-bold(142px, 1);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include din-condensed-bold(190px, 1);
    }

    .title-small {
      @include source-sans-pro-bold(12px);
      color: $light-gray;
      display: block;
      margin-bottom: 10px;
      text-transform: initial;
      width: 100%;

      @media (min-width: #{$ipad-landscape-width}px) {
        @include source-sans-pro-bold(20px);
        margin-bottom: 16px;
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include source-sans-pro-bold(28px);
        margin-bottom: 24px;
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include source-sans-pro-bold(38px);
        margin-bottom: 32px;
      }
    }
  }
}
