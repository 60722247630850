.list-icon-content {
  @include padding-top-bottom(80px, 62px);
  position: relative;

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-top-bottom(42px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-top-bottom(58px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-top-bottom(78px);
  }

  &.light-theme {
    background-color: $white;

    .list-icon-title {
      color: $very-dark-grayish-blue;
    }

    .list-icon-list {
      .list-icon-item {
        .list-item-text {
          color: $very-dark-grayish-blue;
        }
      }
    }
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .list-icon-title {
      color: $white;
    }

    .list-icon-list {
      .list-icon-item {
        .list-item-text {
          color: $white;
        }
      }
    }
  }

  .list-icon-title {
    @include margin-bottom-last-child-0(58px);
    @include padding-left-right(#{$outer-320}px);
    @include source-sans-pro-bold(30px, 1.17);
    text-align: center;

    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(48px);
      @include padding-left-right(#{$outer-1024}px);
      @include source-sans-pro-bold(48px, 1);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(68px);
      @include padding-left-right(#{$outer-1440}px);
      @include source-sans-pro-bold(68px, 1);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(90px);
      @include padding-left-right(#{$outer-1600}px);
      @include source-sans-pro-bold(90px, 1);
    }
  }

  .list-icon-list {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 72px;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-left-right(#{$outer-1024 / 2}px);
      margin-bottom: 0;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-left-right(#{$outer-1440 / 2}px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-left-right(#{$outer-1600 / 2}px);
    }

    .list-icon-item {
      @include padding-left-right(#{$outer-320 + 20}px);
      @include same-min-max-width(100%);

      @media (min-width: #{$iphone-8-width}px) {
        @include padding-left-right(#{$outer-414 + 20}px);
      }

      @media (min-width: #{$ipad-width}px) {
        @include same-min-max-width(50%);
      }

      @media (min-width: #{$ipad-landscape-width}px) {
        @include padding-left-right(#{$outer-1024 / 2}px);
        @include same-min-max-width(25%);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include padding-left-right(#{$outer-1440 / 2}px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include padding-left-right(#{$outer-1600 / 2}px);
      }

      .list-item-icon {
        @include margin-bottom-last-child-0(28px);
        @include margin-left-right(auto);
        display: block;
        height: auto;
        max-width: 134px;

        @media (min-width: #{$ipad-landscape-width}px) {
          @include margin-bottom-last-child-0(32px);
          max-width: 72px;
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include margin-bottom-last-child-0(44px);
          max-width: 100px;
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include margin-bottom-last-child-0(60px);
          max-width: 134px;
        }

        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }

      .list-item-text {
        @include margin-left-right(auto);
        @include source-sans-pro-regular(18px, 1.67);
        max-width: 320px;
        text-align: center;

        @media (min-width: #{$ipad-landscape-width}px) {
          @include margin-left-right(initial);
          @include source-sans-pro-regular(16px);
          max-width: initial;
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-regular(22px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-regular(30px);
        }

        p {
          @include margin-bottom-last-child-0(30px);

          @media (min-width: #{$ipad-landscape-width}px) {
            @include source-sans-pro-regular(20px);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include margin-bottom-last-child-0(26px);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include margin-bottom-last-child-0(36px);
          }
        }
      }
    }
  }

  .list-icon-nav-position {
    line-height: 0;
    margin-top: 42px;
    text-align: center;

    .nav-item {
      @include padding-left-right(3px);
      @include padding-top-bottom(6px);
      display: inline-block;
      line-height: 0;

      .item-button {
        @include same-height-width(6px);
        background-color: $transparent;
        border-radius: 50%;
        border: solid 1px $grayish-blue;
        display: block;

        &.active {
          background-color: $very-light-violet;
        }
      }
    }
  }

  .list-icon-last,
  .list-icon-next {
    @include same-height-width(18px);
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.hide {
      cursor: not-allowed;
      opacity: 0;
    }

    svg {
      display: block;
      fill: $grayish-blue;
      height: auto;
      width: 100%;
    }
  }

  .list-icon-last {
    left: 28px;

    svg {
      transform: rotate(90deg);
    }
  }

  .list-icon-next {
    right: 28px;
    transform: rotate(-90deg);
  }
}
