#main-content {
  left: 0;
  padding-top: #{$header-height-mobile + $header-buttons-height-mobile}px;
  position: relative;
  width: 100%;

  @media (min-width: #{$ipad-landscape-width}px) {
    padding-top: #{$header-height-desktop}px;
  }
}
