.teacher-content {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(80px, 62px);

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414}px);
  }

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(#{$outer-1024}px, 43.5vw);
    @include padding-top-bottom(44px, 68px);
    position: relative;
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(#{$outer-1440}px, 43.5vw);
    @include padding-top-bottom(62px, 96px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(#{$outer-1600}px, 43.5vw);
    @include padding-top-bottom(84px, 128px);
  }

  &.light-theme {
    background-color: $white;

    .teacher-introduction {
      color: $very-dark-grayish-blue;
    }
  
    .teacher-name {
      color: $very-dark-grayish-blue;
    }
  
    .teacher-biography {
      color: $very-dark-grayish-blue;
    }  
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .teacher-introduction {
      color: $white;
    }
  
    .teacher-name {
      color: $white;
    }
  
    .teacher-biography {
      color: $white;
    }  
  }

  .teacher-introduction {
    @include margin-bottom-last-child-0(40px);
    @include source-sans-pro-bold(18px);
    color: $very-dark-grayish-blue;
    text-align: center;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(18px);
      @include source-sans-pro-bold(20px);
      text-align: left;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(24px);
      @include source-sans-pro-bold(28px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(32px);
      @include source-sans-pro-bold(36px);
    }
  }

  .teacher-photo {
    @include margin-bottom-last-child-0(60px);
    @include margin-left-right(auto);
    @include same-height-width(calc(100vw - #{2 * $outer-320}px));
    @include same-max-height-width(352px);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 50%;
    box-shadow: 0 2px 21px 0 rgba($black, 0.5);
    overflow: hidden;

    @media (min-width: #{$iphone-8-width}px) {
      @include same-height-width(calc(100vw - #{2 * $outer-414}px));
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include same-height-width(calc(43.5vw - #{2 * $outer-1024}px));
      @include same-max-height-width(initial);
      position: absolute;
      right: #{$outer-1024}px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include same-height-width(calc(43.5vw - #{2 * $outer-1440}px));
      right: #{$outer-1440}px;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include same-height-width(calc(43.5vw - #{2 * $outer-1600}px));
      right: #{$outer-1600}px;
    }

    .photo-file {
      display: block;
      height: 100%;
      opacity: 0;
      width: 100%;
    }
  }

  .teacher-name {
    @include margin-bottom-last-child-0(24px);
    @include source-sans-pro-bold(30px);
    color: $very-dark-grayish-blue;
    text-align: center;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(18px);
      @include margin-right-last-child-0(18px);
      @include source-sans-pro-bold(48px, 1);
      display: inline-block;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(24px);
      @include margin-right-last-child-0(24px);
      @include source-sans-pro-bold(68px, 1);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(32px);
      @include margin-right-last-child-0(32px);
      @include source-sans-pro-bold(90px, 1);
    }
  }

  .teacher-social-list {
    @include margin-bottom-last-child-0(30px);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(18px);
      display: inline-flex;
      justify-content: flex-end;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(24px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(32px);
    }

    .social-item {
      @include margin-right-last-child-0(8px);
      @include same-min-max-width(40px);
      display: block;

      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-right-last-child-0(8px);
        @include same-min-max-width(28px);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-right-last-child-0(10px);
        @include same-min-max-width(38px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-right-last-child-0(12px);
        @include same-min-max-width(52px);
      }

      a {
        display: block;
        width: 100%;

        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .teacher-biography {
    @include source-sans-pro-regular(18px, 1.67);
    color: $very-dark-grayish-blue;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include source-sans-pro-regular(16px);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include source-sans-pro-regular(22px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include source-sans-pro-regular(30px);
    }

    p {
      @include margin-bottom-last-child-0(30px);

      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(20px);
        @include source-sans-pro-regular(16px);
      }
  
      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-bottom-last-child-0(26px);
        @include source-sans-pro-regular(22px);
      }
  
      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-bottom-last-child-0(36px);
        @include source-sans-pro-regular(30px);
      }
  
    }
  }

  .read-more-biography {
    @include margin-left-right(auto);
    @include source-sans-pro-regular(18px);
    color: $very-light-violet;
    display: none;
    margin-top: 40px;
    padding-right: 28px;
    position: relative;

    @media (min-width: #{$ipad-landscape-width}px) {
      display: none;
    }

    .icon {
      @include same-height-width(18px);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(0);

      svg {
        fill: $very-light-violet;
        height: auto;
        width: 100%;
      }
    }
  }
}
