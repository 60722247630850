@font-face {
  font-family: SourceSansPro;
    src: url(#{$fonts-path}/SourceSansPro-Bold.eot);
    src: url(#{$fonts-path}/SourceSansPro-Bold.eot?#iefix) format('embedded-opentype'),
      url(#{$fonts-path}/SourceSansPro-Bold.woff2) format('woff2'),
      url(#{$fonts-path}/SourceSansPro-Bold.woff) format('woff'),
      url(#{$fonts-path}/SourceSansPro-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: SourceSansPro;
  src: url(#{$fonts-path}/SourceSansPro-BoldItalic.eot);
  src: url(#{$fonts-path}/SourceSansPro-BoldItalic.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SourceSansPro-BoldItalic.woff2) format('woff2'),
    url(#{$fonts-path}/SourceSansPro-BoldItalic.woff) format('woff'),
    url(#{$fonts-path}/SourceSansPro-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: SourceSansPro;
  src: url(#{$fonts-path}/SourceSansPro-Regular.eot);
  src: url(#{$fonts-path}/SourceSansPro-Regular.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SourceSansPro-Regular.woff2) format('woff2'),
    url(#{$fonts-path}/SourceSansPro-Regular.woff) format('woff'),
    url(#{$fonts-path}/SourceSansPro-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SourceSansPro;
    src: url(#{$fonts-path}/SourceSansPro-Italic.eot);
    src: url(#{$fonts-path}/SourceSansPro-Italic.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SourceSansPro-Italic.woff2) format('woff2'),
    url(#{$fonts-path}/SourceSansPro-Italic.woff) format('woff'),
    url(#{$fonts-path}/SourceSansPro-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: SourceSansPro;
  src: url(#{$fonts-path}/SourceSansPro-SemiBoldItalic.eot);
  src: url(#{$fonts-path}/SourceSansPro-SemiBoldItalic.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SourceSansPro-SemiBoldItalic.woff2) format('woff2'),
    url(#{$fonts-path}/SourceSansPro-SemiBoldItalic.woff) format('woff'),
    url(#{$fonts-path}/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: SourceSansPro;
  src: url(#{$fonts-path}/SourceSansPro-SemiBold.eot);
  src: url(#{$fonts-path}/SourceSansPro-SemiBold.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/SourceSansPro-SemiBold.woff2) format('woff2'),
    url(#{$fonts-path}/SourceSansPro-SemiBold.woff) format('woff'),
    url(#{$fonts-path}/SourceSansPro-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: DINCondensed;
  src: url(#{$fonts-path}/DINCondensed-Bold.eot);
  src: url(#{$fonts-path}/DINCondensed-Bold.eot?#iefix) format('embedded-opentype'),
    url(#{$fonts-path}/DINCondensed-Bold.woff2) format('woff2'),
    url(#{$fonts-path}/DINCondensed-Bold.woff) format('woff'),
    url(#{$fonts-path}/DINCondensed-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}