.learnings-content {
  @include padding-top-bottom(74px, 46px);
  position: relative;

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-top-bottom(34px, 38px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-top-bottom(48px, 54px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-top-bottom(64px, 72px);
  }

  &.light-theme {
    background-color: $white;
    color: $very-dark-grayish-blue;

    .learnings-lists-wrapper {
      .learnings-list {
        .list-item {
          .item-text {
            color: $very-dark-grayish-blue;
          }
        }
      }
    }
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;
    color: $white;
  
    .learnings-lists-wrapper {
      .learnings-list {
        .list-item {
          .item-text {
            color: $mostly-white;
          }
        }
      }
    }
  }

  .learnings-title {
    @include margin-bottom-last-child-0(64px);
    @include padding-left-right(#{$outer-320}px);
    @include source-sans-pro-bold(30px, 1.17);

    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(36px);
      @include padding-left-right(#{$outer-1024}px, #{$outer-1024 / 2}px);
      @include source-sans-pro-bold(48px, 1);
      max-width: calc(60vw);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(52px);
      @include padding-left-right(#{$outer-1440}px, #{$outer-1440 / 2}px);
      @include source-sans-pro-bold(68px, 1);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(70px);
      @include padding-left-right(#{$outer-1600}px, #{$outer-1600 / 2}px);
      @include source-sans-pro-bold(90px, 1);
    }
  }

  .learnings-lists-wrapper {
    display: flex;
    flex-wrap: nowrap;

    .learnings-list {
      @include padding-left-right(#{$outer-320}px, 66px);
      @include same-min-max-width(100%);

      @media (min-width: #{$iphone-8-width}px) {
        @include padding-left-right(#{$outer-414}px, 66px);
      }

      @media (min-width: #{$ipad-width}px) {
        @include same-min-max-width(50%);
      }

      @media (min-width: #{$ipad-landscape-width}px) {
        @include padding-left-right(#{$outer-1024 / 2}px);
        @include same-min-max-width(33.33%);
      }

      &:nth-child(2n + 1) {
        @media (min-width: #{$ipad-width}px) {
          @include padding-left-right(#{$outer-414}px);
        }
      }

      &:nth-child(2n + 2) {
        @media (min-width: #{$ipad-width}px) {
          @include padding-left-right(#{$outer-414}px, 66px);
        }
      }

      &:nth-child(3n + 1) {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024}px, #{$outer-1024 / 2}px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(#{$outer-1024}px, #{$outer-1440 / 2}px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(#{$outer-1024}px, #{$outer-1600 / 2}px);
        }
      }

      &:nth-child(3n + 3) {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024 / 2}px, #{$outer-1024}px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(#{$outer-1440 / 2}px, #{$outer-1024}px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(#{$outer-1600 / 2}px, #{$outer-1024}px);
        }
      }

      .list-item {
        @include margin-bottom-last-child-0(28px);
        min-height: 36px;
        padding-left: 100px;
        position: relative;

        @media (min-width: #{$ipad-landscape-width}px) {
          @include margin-bottom-last-child-0(54px);
          left: 0;
          padding-left: 60px;
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include margin-bottom-last-child-0(76px);
          padding-left: 84px;
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include margin-bottom-last-child-0(102px);
          padding-left: 112px;
        }

        .item-icon {
          @include same-height-width(36px);
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          left: 30px;
          position: absolute;
          top: 0;

          @media (min-width: #{$ipad-landscape-width}px) {
            @include same-height-width(38px);
            left: 0;
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include same-height-width(54px);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include same-height-width(72px);
          }
        }

        .item-text {
          @include source-sans-pro-regular(18px, 1.67);

          @media (min-width: #{$ipad-landscape-width}px) {
            @include source-sans-pro-regular(16px);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include source-sans-pro-regular(22px);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include source-sans-pro-regular(30px);
          }
        }
      }
    }
  }

  .learnings-lists-nav-position {
    line-height: 0;
    margin-top: 42px;
    text-align: center;

    .nav-item {
      @include padding-left-right(3px);
      @include padding-top-bottom(6px);
      display: inline-block;
      line-height: 0;

      .item-button {
        @include same-height-width(6px);
        background-color: $transparent;
        border-radius: 50%;
        border: solid 1px $grayish-cyan;
        display: block;

        &.active {
          background-color: $very-light-violet;
        }
      }
    }
  }

  .learnings-lists-last,
  .learnings-lists-next {
    @include same-height-width(18px);
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.hide {
      cursor: not-allowed;
      opacity: 0;
    }

    svg {
      display: block;
      fill: $grayish-blue;
      height: auto;
      width: 100%;
    }
  }

  .learnings-lists-last {
    left: 28px;

    svg {
      transform: rotate(90deg);
    }
  }

  .learnings-lists-next {
    right: 28px;
    transform: rotate(-90deg);
  }
}
