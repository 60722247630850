.video-gallery-content {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(56px, 82px);

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414}px);
  }

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(#{1.5 * $outer-1024}px);
    @include padding-top-bottom(38px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(#{1.5 * $outer-1440}px);
    @include padding-top-bottom(54px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(#{1.5 * $outer-1600}px);
    @include padding-top-bottom(72px);
  }

  &.light-theme {
    background-color: $white;
    color: $very-dark-grayish-blue;
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;
    color: $white;
  }

  .video-gallery-title {
    @include margin-bottom-last-child-0(54px);
    @include padding-left-right(#{$outer-320}px);
    @include source-sans-pro-bold(30px, 1.17);
    text-align: center;

    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(18px);
      @include source-sans-pro-bold(48px);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(26px);
      @include source-sans-pro-bold(68px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(34px);
      @include source-sans-pro-bold(90px);
    }
  }

  .video-gallery-list {
    @media (min-width: #{$ipad-landscape-width}px) {
      display: flex;
    }

    .gallery-item {
      @include margin-bottom-last-child-0(70px);

      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(20px);
        flex-basis: 50%;
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-bottom-last-child-0(28px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-bottom-last-child-0(36px);
      }

      &:first-child {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(0, #{$outer-1024 / 2}px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(0, #{$outer-1440 / 2}px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(0, #{$outer-1600 / 2}px);
        }
      }

      &:last-child {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024 / 2}px, 0);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(#{$outer-1440 / 2}px, 0);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(#{$outer-1600 / 2}px, 0);
        }
      }

      .video-file {
        display: block;
        height: auto;
        margin-bottom: 22px;
        width: 100%;
      }

      .video-title {
        @include source-sans-pro-bold(18px, 1.33);

        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(16px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(22px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(30px);
        }
      }
    }
  }
}
