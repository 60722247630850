// Paths
$images-path: '../assets/images';
$fonts-path: '../assets/fonts';

// Colors
$black: #000;
$darker-grayish-blue: #2e373e;
$very-dark-grayish-blue: #3d464d;
$dark-grayish-blue: #4e555b;
$grayish-blue: #50575d;
$light-grayish-blue: #6d7278;
$very-light-violet: #cb89ff;
$white: #fff;
$transparent: rgba(0, 0, 0, 0);
$light-gray: #e0e0e0;
$grayish-cyan: #c6d2d1;
$mostly-white: #fefefe;
$light-grayish-violet: #c8bce7;

// Z-indexes
$z-moon: 9999;
$z-space: 999;
$z-sky: 99;
$z-hills: 9;
$z-floor: 0;
$z-underground: -9;
$z-bedrock: -99;

// iPhone (320 x 480)
$iphone-height: 480;
$iphone-width: 320;

// iPhone Se (320 x 568)
$iphone-se-height: 568;
$iphone-se-width: 320;

// iPhone 8 (375 x 667)
$iphone-8-height: 667;
$iphone-8-width: 375;

// iPhone Plus (414 x 736)
$iphone-plus-height: 736;
$iphone-plus-width: 414;

// iPhone X (414 x 812)
$iphone-x-height: 896;
$iphone-x-width: 414;

// iPhone Xs (375 x 812)
$iphone-xr-height: 812;
$iphone-xr-width: 375;

// iPhone Xr (414 x 896)
$iphone-xr-height: 896;
$iphone-xr-width: 414;

// iPad (768 x 1024)
$ipad-height: 1024;
$ipad-width: 768;

// iPad Landscape (1024 x 768)
$ipad-landscape-height: $ipad-width;
$ipad-landscape-width: $ipad-height;

// Desktop WXGA-HD (1280 x 720)
$desktop-wxga-hd-height: 720;
$desktop-wxga-hd-width: 1280;

// Desktop WXGA (1366 x 768)
$desktop-wxga-height: 768;
$desktop-wxga-width: 1366;

// Desktop WXGA Plus (1440 x 900)
$desktop-wxga-plus-height: 900;
$desktop-wxga-plus-width: 1440;

// Desktop UXGA (1600 x 900)
$desktop-uxga-height: 900;
$desktop-uxga-width: 1600;

// Desktop Full HD (1920 x 1080)
$desktop-full-hd-height: 1080;
$desktop-full-hd-width: 1920;

// Outers
$outer-320: 20;
$outer-414: 42;
$outer-1024: 74;
$outer-1440: 104;
$outer-1600: 140;

// Functions
@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

// Others Sizes
$header-buttons-height-mobile: 74;
$header-height-mobile: 60;
$header-height-desktop: 90;
