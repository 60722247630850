.start-course-content {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(32px);
  text-align: center;

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414}px);
  }

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-top-bottom(34px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-top-bottom(48px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-top-bottom(64px);
  }

  &.light-theme {
    background-color: $white;
  }

  &.dark-theme {
    background-color: $darker-grayish-blue;
  }

  .start-course-link {
    @include padding-left-right(32px);
    @include padding-top-bottom(14px);
    @include source-sans-pro-bold(18px, 22px);
    background-color: $very-light-violet;
    border-radius: 8px;
    color: $white;
    display: inline-block;
    text-transform: uppercase;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-left-right(38px);
      @include padding-top-bottom(14px);
      @include source-sans-pro-bold(14px);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-left-right(52px);
      @include padding-top-bottom(18px);
      @include source-sans-pro-bold(18px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-left-right(70px);
      @include padding-top-bottom(24px);
      @include source-sans-pro-bold(24px);
    }
  }
}
