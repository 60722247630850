#header {
  background-color: $transparent;
  box-shadow: 0 2px 21px -2px rgba($black, 0.5);
  height: #{$header-height-mobile + $header-buttons-height-mobile}px;
  overflow-y: hidden;
  position: fixed;
  transition: height 600ms;
  width: 100%;
  z-index: $z-space;

  @media (min-width: #{$ipad-landscape-width}px) {
    height: #{$header-height-desktop}px;
    transition: none;
  }

  &.show-menu {
    background-color: $very-dark-grayish-blue;
    height: 100vh;
    overflow-y: auto;

    @media (min-width: #{$ipad-landscape-width}px) {
      background-color: $transparent;
      height: #{$header-height-desktop}px;
      overflow-y: auto;
    }

    .header-body {
      max-height: 9999em;
      padding-top: #{$header-height-mobile}px;

      @media (min-width: #{$ipad-landscape-width}px) {
        max-height: initial;
        padding-top: 0;
      }

      .menu-wrapper {
        margin-bottom: 0;
        opacity: 1;
      }

      .header-footer {
        bottom: initial;
        left: initial;
        margin-top: 140px;
        position: relative;

        @media (min-width: #{$ipad-landscape-width}px) {
          margin-top: 0;
          position: initial;
        }
      }
    }
  }

  .header-top {
    background-color: $white;
    height: #{$header-height-mobile}px;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-left-right(#{$outer-1024}px);
      background-color: $very-dark-grayish-blue;
      height: #{$header-height-desktop}px;
      text-align: left;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-left-right(#{$outer-1440}px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-left-right(#{$outer-1600}px);
    }

    .logo-title {
      display: inline-block;
      height: 100%;

      .logo-link {
        letter-spacing: inherit;

        svg {
          display: block;
          fill: $very-dark-grayish-blue;
          height: 100%;
          width: auto;

          @media (min-width: #{$ipad-landscape-width}px) {
            fill: $white;
          }
        }
      }
    }

    .toggle-menu {
      @include same-height-width(20px);
      background-image: url(#{$images-path}/icone-menu.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      left: 20px;
      position: absolute;
      top: 20px;

      @media (min-width: #{$ipad-landscape-width}px) {
        display: none;
      }
    }
  }

  .header-body {
    background-color: $white;
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    transition: max-height 600ms, padding-top 300ms;

    @media (min-width: #{$ipad-landscape-width}px) {
      max-height: initial;
      overflow: initial;
      padding-top: 0;
      transition: none;
    }

    .menu-wrapper {
      margin-bottom: 140px;
      opacity: 0;
      padding-top: 52px;
      transition: opacity 300ms;

      @media (min-width: #{$ipad-landscape-width}px) {
        margin-bottom: 0;
        opacity: 1;
        padding-top: 0;
        transition: none;
      }

      .menu-title {
        @include source-sans-pro-bold(24px);
        color: $very-dark-grayish-blue;
        margin-bottom: 40px;
        text-align: center;

        @media (min-width: #{$ipad-landscape-width}px) {
          display: none;
        }
      }

      .anchors-nav {
        margin-bottom: 72px;

        @media (min-width: #{$ipad-landscape-width}px) {
          margin-bottom: 0;
          position: absolute;
          right: #{$outer-1024}px;
          top: 50%;
          transform: translateY(-50%);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          right: #{$outer-1440}px;
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          right: #{$outer-1600}px;
        }

        .nav-list {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: flex;
          }

          .nav-item {
            @include margin-bottom-last-child-0(16px);
            @include source-sans-pro-regular(24px);
            color: $very-dark-grayish-blue;
            text-align: center;

            @media (min-width: #{$ipad-landscape-width}px) {
              @include margin-right-last-child-0(24px);
              @include source-sans-pro-bold(16px);
              color: $very-light-violet;
              margin-bottom: 0;
            }

            @media (min-width: #{$desktop-wxga-width}px) {
              @include margin-right-last-child-0(52px);
              @include source-sans-pro-bold(24px);
            }

            .nav-link {
              display: inline-block;
            }
          }
        }
      }

      .teacher-social {
        @media (min-width: #{$ipad-landscape-width}px) {
          display: none;
        }

        .teacher-name {
          @include source-sans-pro-regular(24px);
          color: $very-dark-grayish-blue;
          margin-bottom: 20px;
          text-align: center;
        }

        .social-list {
          display: flex;
          justify-content: center;

          .social-item {
            display: block;
            margin-right: 8px;
            width: 40px;

            &:last-child {
              margin-right: 0;
            }

            .social-link {
              display: block;
              line-height: 0;

              .link-icon {
                display: block;
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .header-footer {
      @include padding-top-bottom(20px);
      background-color: $very-dark-grayish-blue;
      bottom: 0;
      margin-top: 0;
      position: absolute;
      text-align: center;
      transition: margin-top 600ms;
      width: 100%;

      @media (min-width: #{$ipad-landscape-width}px) {
        @include padding-top-bottom(0);
        background-color: transparent;
        left: 50%;
        margin-top: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-90%, calc(-50% + 4px));
        transition: none;
        width: initial;
      }

      @media (min-width: #{$desktop-wxga-width}px) {
        transform: translate(-100%, calc(-50% - 8px));
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        transform: translate(-90%, calc(-50% - 8px));
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        transform: translate(-80%, calc(-50% - 8px));
      }

      @media (min-width: #{$desktop-full-hd-width}px) {
        transform: translate(-50%, calc(-50% - 8px));
      }

      .interested,
      .start-course {
        @include source-sans-pro-bold(16px, 14px);
        color: $white;
        display: inline-block;

        @media (min-width: #{$desktop-wxga-width}px) {
          @include source-sans-pro-bold(24px, 24px);
        }
      }

      .interested {
        @include padding-left-right(28px, 0);
        @include padding-top-bottom(10px);
        display: none;
        margin-right: 20px;
        position: relative;

        @media (min-width: #{$desktop-wxga-width}px) {
          @include padding-left-right(58px, 0);
          @include padding-top-bottom(18px);
          margin-right: 40px;
        }

        .icon {
          display: block;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;

          @media (min-width: #{$desktop-wxga-width}px) {
            width: 38px;
          }

          svg {
            display: block;
            fill: $white;
            height: auto;
            width: 100%;

            .fill {
              fill: transparent;
            }
          }
        }
      }

      .start-course {
        @include padding-left-right(22px);
        @include padding-top-bottom(10px);
        background-color: $very-light-violet;
        border-radius: 8px;

        @media (min-width: #{$desktop-wxga-width}px) {
          @include padding-left-right(22px);
          @include padding-top-bottom(18px);
        }
      }
    }
  }
}
