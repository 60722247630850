.banner-content {
  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(#{$outer-1024}px);
    @include padding-top-bottom(16px, 54px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(#{$outer-1440}px);
    @include padding-top-bottom(22px, 78px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(#{$outer-1600}px);
    @include padding-top-bottom(30px, 104px);
  }

  &.light-theme {
    background-color: $white;

    .banner-background {
      .course-information {
        .information-label {
          @media (min-width: #{$ipad-landscape-width}px) {
            color: $light-grayish-blue;
          }
        }
    
        .information-name {
          color: $very-dark-grayish-blue;
        }
      }
    
      .price-information {
        .old-price {
          color: $light-grayish-blue;
        }
    
        .current-price {
          color: $very-light-violet;
        }
    
        .price-installments {
          color: $light-grayish-blue;
        }
      }
    }
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .banner-background {
      .course-information {
        .information-label {
          @media (min-width: #{$ipad-landscape-width}px) {
            color: $grayish-cyan;
          }
        }
    
        .information-name {
          color: $white;
        }
      }
    
      .price-information {
        .old-price {
          color: $grayish-cyan;
        }
    
        .price-installments {
          color: $grayish-cyan;
        }
      }
    }
  }

  .banner-background {
    @include padding-left-right(#{$outer-320}px);
    @include padding-top-bottom(40px, 88px);
  
    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-left-right(42px);
      @include padding-top-bottom(28px);
      border-radius: 8px;
      border: solid 3px $very-light-violet;
      display: flex;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-left-right(60px);
      @include padding-top-bottom(40px);
      border: solid 4px $very-light-violet;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-top-bottom(54px);
      border: solid 5px $very-light-violet;
    }

    .course-information,
    .price-information,
    .start-link {
      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(0);
        @include padding-left-right(21px);
        flex-basis: 33.33%;
      }
  
      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include padding-left-right(30px);
      }
  
      @media (min-width: #{$desktop-full-hd-width}px) {
        @include padding-left-right(40px);
      }
  
      &:first-child {
        padding-left: 0;
      }
  
      &:last-child {
        padding-right: 0;
      }
    }

    .course-information {
      @include margin-bottom-last-child-0(54px);
  
      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(0);
      }
  
      .information-label {
        display: none;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-semibold(14px);
          display: block;
          margin-bottom: 2px;
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-semibold(18px);
          margin-bottom: 4px;
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-semibold(20px);
          margin-bottom: 6px;
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include source-sans-pro-semibold(24px);
        }
      }
  
      .information-name {
        @include source-sans-pro-bold(30px, 1.17);
        text-align: center;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(32px, 1);
          text-align: left;
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(44px, 1);
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(52px, 1);
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include source-sans-pro-bold(60px, 1);
        }
      }
    }

    .price-information {
      text-align: center;
  
      .old-price {
        @include margin-bottom-last-child-0(12px);
        @include source-sans-pro-bold(35px);
        display: inline-block;
        position: relative;
        text-align: center;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(24px);
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(34px);
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(40px);
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include source-sans-pro-bold(46px);
        }
  
        &:before {
          background-color: $very-light-violet;
          content: '';
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
  
          @media (min-width: #{$ipad-landscape-width}px) {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120%;
          }
        }
      }
  
      .current-price {
        @include source-sans-pro-bold(60px);
        color: $very-light-violet;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(48px);
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(68px);
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(78px);
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include source-sans-pro-bold(90px);
        }
      }
  
      .price-installments {
        @include source-sans-pro-regular(18px, normal, normal, italic);
        margin-top: 8px;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include source-sans-pro-bold(14px, normal, normal, italic);
          margin-top: 0;
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-bold(18px, normal, normal, italic);
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include source-sans-pro-bold(20px, normal, normal, italic);
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include source-sans-pro-bold(24px, normal, normal, italic);
        }
      }
    }

    .start-link {
      text-align: center;
  
      a {
        @include padding-left-right(32px);
        @include padding-top-bottom(14px);
        @include source-sans-pro-bold(18px, 22px);
        background-color: $very-light-violet;
        border-radius: 8px;
        color: $white;
        display: inline-block;
        margin-top: 56px;
        text-transform: uppercase;
  
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(38px);
          @include padding-top-bottom(14px);
          @include source-sans-pro-bold(14px);
          margin-top: 0;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
  
        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(52px);
          @include padding-top-bottom(18px);
          @include source-sans-pro-bold(18px);
        }
  
        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(60px);
          @include padding-top-bottom(20px);
          @include source-sans-pro-bold(20px);
        }
  
        @media (min-width: #{$desktop-full-hd-width}px) {
          @include padding-left-right(70px);
          @include padding-top-bottom(24px);
          @include source-sans-pro-bold(24px);
        }
      }
    }
  }
}
