.testimonials-content {
  @include padding-top-bottom(54px, 40px);
  position: relative;

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-top-bottom(40px, 22px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-top-bottom(56px, 30px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-top-bottom(74px, 40px);
  }

  &.light-theme {
    background-color: $white;

    .testimonials-title {
      color: $very-dark-grayish-blue;
    }
  
    .testimonials-list {
      .testimonial-item {
        .item-box {
          .item-text {
            color: $light-grayish-blue;
          }
  
          .item-author-work {
            color: $light-grayish-blue;
          }
        }
      }
    }
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .testimonials-title {
      color: $white;
    }
  
    .testimonials-list {
      .testimonial-item {
        .item-box {
          .item-text {
            color: $grayish-cyan;
          }
  
          .item-author-work {
            color: $grayish-cyan;
          }
        }
      }
    }
  }

  .testimonials-title {
    @include margin-bottom-last-child-0(54px);
    @include padding-left-right(#{$outer-320}px);
    @include source-sans-pro-bold(30px, 1.17);
    text-align: center;

    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(38px);
      @include padding-left-right(#{$outer-1024}px);
      @include source-sans-pro-bold(48px);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(54px);
      @include padding-left-right(#{$outer-1440}px);
      @include source-sans-pro-bold(68px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(72px);
      @include padding-left-right(#{$outer-1600}px);
      @include source-sans-pro-bold(90px);
    }
  }

  .testimonials-list {
    display: flex;
    flex-wrap: nowrap;

    @media (min-width: #{$ipad-landscape-width}px) {
      align-items: flex-start;
    }

    .testimonial-item {
      @include padding-left-right(#{1.5 * $outer-320}px);
      @include same-min-max-width(100%);

      @media (min-width: #{$iphone-8-width}px) {
        @include padding-left-right(#{1.5 * $outer-414}px);
      }

      @media (min-width: #{$ipad-width}px) {
        @include same-min-max-width(50%);
      }

      @media (min-width: #{$ipad-landscape-width}px) {
        @include padding-left-right(#{$outer-1024 / 2}px);
        @include same-min-max-width(33.33%);
      }

      &:nth-child(2n + 1) {
        @media (min-width: #{$ipad-width}px) {
          @include padding-left-right(#{1.5 * $outer-414}px, #{$outer-414 / 2}px);
        }
      }

      &:nth-child(2n + 2) {
        @media (min-width: #{$ipad-width}px) {
          @include padding-left-right(#{$outer-414 / 2}px, #{1.5 * $outer-414}px);
        }
      }

      &:nth-child(3n + 1) {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024}px, #{$outer-1024 / 2}px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(#{$outer-1440}px, #{$outer-1440 / 2}px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(#{$outer-1600}px, #{$outer-1600 / 2}px);
        }
      }

      &:nth-child(3n + 2) {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024 / 2}px);
        }
      }

      &:nth-child(3n + 3) {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(#{$outer-1024 / 2}px, #{$outer-1024}px);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(#{$outer-1440 / 2}px, #{$outer-1440}px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(#{$outer-1600 / 2}px, #{$outer-1600}px);
        }
      }

      .item-box {
        @include padding-left-right(30px);
        @include padding-top-bottom(42px);
        border-radius: 12px;
        border: solid 2px $very-light-violet;

        .item-text {
          @include margin-bottom-last-child-0(38px);
          @include source-sans-pro-regular(18px, 1.67, normal, italic);

          @media (min-width: #{$ipad-landscape-width}px) {
            @include margin-bottom-last-child-0(32px);
            @include source-sans-pro-regular(14px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include margin-bottom-last-child-0(44px);
            @include source-sans-pro-regular(18px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include margin-bottom-last-child-0(60px);
            @include source-sans-pro-regular(24px, normal, normal, italic);
          }

          p {
            @include margin-bottom-last-child-0(38px);

            @media (min-width: #{$ipad-landscape-width}px) {
              @include margin-bottom-last-child-0(16px);
            }

            @media (min-width: #{$desktop-wxga-plus-width}px) {
              @include margin-bottom-last-child-0(22px);
            }

            @media (min-width: #{$desktop-uxga-width}px) {
              @include margin-bottom-last-child-0(28px);
            }
          }
        }

        .item-author {
          @include source-sans-pro-bold(18px, normal, normal, italic);
          color: $very-light-violet;
          text-align: center;

          @media (min-width: #{$ipad-landscape-width}px) {
            @include source-sans-pro-bold(16px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include source-sans-pro-bold(22px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include source-sans-pro-bold(28px, normal, normal, italic);
          }
        }

        .item-author-work {
          @include source-sans-pro-regular(18px, normal, normal, italic);
          text-align: center;

          @media (min-width: #{$ipad-landscape-width}px) {
            @include source-sans-pro-regular(16px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include source-sans-pro-regular(22px, normal, normal, italic);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include source-sans-pro-regular(28px, normal, normal, italic);
          }
        }
      }
    }
  }

  .testimonials-nav-position {
    line-height: 0;
    margin-top: 42px;
    text-align: center;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-top-bottom(14px, 0);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-top-bottom(18px, 0);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-top-bottom(24px, 0);
    }

    .nav-item {
      @include padding-left-right(3px);
      @include padding-top-bottom(6px);
      display: inline-block;
      line-height: 0;

      .item-button {
        @include same-height-width(6px);
        background-color: $transparent;
        border-radius: 50%;
        border: solid 1px $grayish-blue;
        display: block;

        &.active {
          background-color: $very-light-violet;
        }
      }
    }
  }

  .testimonials-last,
  .testimonials-next {
    @include same-height-width(18px);
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: #{$ipad-landscape-width}px) {
      @include same-height-width(18px);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include same-height-width(26px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include same-height-width(34px);
    }

    &.hide {
      cursor: not-allowed;
      opacity: 0;
    }

    svg {
      display: block;
      fill: $grayish-blue;
      height: auto;
      width: 100%;
    }
  }

  .testimonials-last {
    left: 18px;

    @media (min-width: #{$ipad-landscape-width}px) {
      left: 24px;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      left: 32px;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      left: 44px;
    }

    svg {
      transform: rotate(90deg);
    }
  }

  .testimonials-next {
    right: 18px;
    transform: rotate(-90deg);

    @media (min-width: #{$ipad-landscape-width}px) {
      right: 24px;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      right: 32px;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      right: 44px;
    }
  }
}
