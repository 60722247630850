.target-content {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(52px, 76px);

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414}px);
  }

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(45vw, #{$outer-1024}px);
    @include padding-top-bottom(28px, 76px);
    position: relative;
    min-height: #{28 + 320 + 76}px;
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(45vw, #{$outer-1440}px);
    @include padding-top-bottom(40px, 76px);
    min-height: #{40 + 460 + 76}px;
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(45vw, #{$outer-1600}px);
    @include padding-top-bottom(54px, 76px);
    min-height: #{54 + 460 + 76}px;
  }

  @media (min-width: #{$desktop-full-hd-width}px) {
    min-height: #{54 + 620 + 76}px;
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .target-title {
      color: $white;
    }
  
    .target-text-columns {
      .text-column {
        color: $white;
      }
    }
  }

  &.light-theme {
    background-color: $white;

    .target-title {
      color: $very-dark-grayish-blue;
    }
  
    .target-text-columns {
      .text-column {
        color: $very-dark-grayish-blue;
      }
    }
  }

  .target-title {
    @include margin-bottom-last-child-0(40px);
    @include source-sans-pro-bold(30px, 1.17);
    text-align: center;

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(32px);
      @include source-sans-pro-bold(48px);
      text-align: left;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(44px);
      @include source-sans-pro-bold(68px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(60px);
      @include source-sans-pro-bold(90px);
    }
  }

  .target-image {
    @include margin-bottom-last-child-0(52px);
    @include margin-left-right(auto);
    @include same-height-width(calc(100vw - #{2 * $outer-320}px));
    @include same-max-height-width(352px);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    position: relative;

    @media (min-width: #{$iphone-8-width}px) {
      @include same-height-width(calc(100vw - #{2 * $outer-414}px));
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include same-height-width(calc(45vw - #{2 * $outer-1024}px));
      @include same-max-height-width(320px);
      left: #{$outer-1024}px;
      margin-bottom: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: #{$desktop-wxga-hd-width}px) {
      left: #{2 * $outer-1024}px;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include same-max-height-width(460px);
      @include same-height-width(calc(45vw - #{2 * $outer-1440}px));
      left: #{$outer-1440}px;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include same-height-width(calc(45vw - #{2 * $outer-1600}px));
      left: #{$outer-1600}px;
    }

    @media (min-width: #{$desktop-full-hd-width}px) {
      @include same-max-height-width(620px);
    }

    .image-file {
      @include same-height-width(100%);
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: $z-hills;
    }
  }

  .target-text-columns {
    @media (min-width: #{$ipad-landscape-width}px) {
      display: flex;
      flex-wrap: nowrap;
    }

    .text-column {
      @include source-sans-pro-regular(18px, 1.67);

      @media (min-width: #{$ipad-landscape-width}px) {
        @include same-min-max-width(50%);
        @include source-sans-pro-regular(16px);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include source-sans-pro-regular(22px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include source-sans-pro-regular(30px);
      }

      &.left-column {
        @include margin-bottom-last-child-0(30px);

        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(0, 22px);
          margin-bottom: 0;
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(0, 30px);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(0, 40px);
        }
      }

      &.right-column {
        @media (min-width: #{$ipad-landscape-width}px) {
          @include padding-left-right(22px, 0);
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include padding-left-right(30px, 0);
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include padding-left-right(40px, 0);
        }
      }

      p {
        @include margin-bottom-last-child-0(30px);
      }
    }
  }
}
