.carousel-component {
  &[step-mobile="2"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(2n + 1) {
          display: inline-block;
        }

        &:nth-child(2n + 2) {
          display: none;
        }
      }
    }
  }

  &[step-mobile="3"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(3n + 1) {
          display: inline-block;
        }

        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          display: none;
        }
      }
    }
  }

  &[step-mobile="4"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(4n + 1) {
          display: inline-block;
        }

        &:nth-child(4n + 2),
        &:nth-child(4n + 3),
        &:nth-child(4n + 4) {
          display: none;
        }
      }
    }
  }

  &[step-tablet="2"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(2n + 1) {
          @media (min-width: #{$ipad-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(2n + 2) {
          @media (min-width: #{$ipad-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &[step-tablet="3"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(3n + 1) {
          @media (min-width: #{$ipad-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @media (min-width: #{$ipad-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &[step-tablet="4"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(4n + 1) {
          @media (min-width: #{$ipad-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(4n + 2),
        &:nth-child(4n + 3),
        &:nth-child(4n + 4) {
          @media (min-width: #{$ipad-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &[step-desktop="2"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(2n + 1) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(2n + 2) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &[step-desktop="3"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(3n + 1) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &[step-desktop="4"] {
    & + .carousel-nav-position {
      .carousel-nav-bullet {
        &:nth-child(4n + 1) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: inline-block;
          }
        }

        &:nth-child(4n + 2),
        &:nth-child(4n + 3),
        &:nth-child(4n + 4) {
          @media (min-width: #{$ipad-landscape-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &.hide-nav-mobile {
    & + .carousel-nav-position {
      display: none;

      @media (min-width: #{$ipad-landscape-width}px) {
        display: block;
      }

      & + .carousel-nav-last {
        display: none;

        @media (min-width: #{$ipad-landscape-width}px) {
          display: block;
        }

        & + .carousel-nav-next {
          display: none;

          @media (min-width: #{$ipad-landscape-width}px) {
            display: block;
          }
        }
      }
    }
  }

  &.hide-nav-tablet {
    .carousel-box {
      @media (min-width: #{$ipad-width}px) {
        transition: none;
      }
    }

    & + .carousel-nav-position {
      display: block;

      @media (min-width: #{$ipad-width}px) {
        display: none;
      }

      & + .carousel-nav-last {
        display: block;

        @media (min-width: #{$ipad-width}px) {
          display: none;
        }

        & + .carousel-nav-next {
          display: block;

          @media (min-width: #{$ipad-width}px) {
            display: none;
          }
        }
      }
    }
  }

  &.hide-nav-desktop {
    .carousel-box {
      @media (min-width: #{$ipad-landscape-width}px) {
        transition: none;
      }
    }

    & + .carousel-nav-position {
      display: block;

      @media (min-width: #{$ipad-landscape-width}px) {
        display: none;
      }

      & + .carousel-nav-last {
        display: block;

        @media (min-width: #{$ipad-landscape-width}px) {
          display: none;
        }

        & + .carousel-nav-next {
          display: block;

          @media (min-width: #{$ipad-landscape-width}px) {
            display: none;
          }
        }
      }
    }
  }

  .carousel-box {
    transition: transform 600ms;
  }

  .carousel-nav-position {
    &.hide {
      display: none;
    }
  }
}
