.newsletter-content {
  @include padding-left-right(#{$outer-320}px);
  @include padding-top-bottom(72px, 96px);

  @media (min-width: #{$ipad-landscape-width}px) {
    @include padding-left-right(#{$outer-1024}px);
    @include padding-top-bottom(78px, 98px);
  }

  @media (min-width: #{$desktop-wxga-plus-width}px) {
    @include padding-left-right(#{$outer-1440}px);
    @include padding-top-bottom(112px, 140px);
  }

  @media (min-width: #{$desktop-uxga-width}px) {
    @include padding-left-right(#{$outer-1600}px);
    @include padding-top-bottom(148px, 186px);
  }

  @media (min-width: #{$iphone-8-width}px) {
    @include padding-left-right(#{$outer-414 + 24}px);
  }

  &.light-theme {
    background-color: $white;

    .newsletter-title {
      color: $very-dark-grayish-blue;
    }
  
    .newsletter-form {
      .input-text {
        color: $light-grayish-blue;
      }
    }
  }

  &.dark-theme {
    background-color: $very-dark-grayish-blue;

    .newsletter-title {
      color: $white;
    }
  
    .newsletter-form {
      .input-text {
        color: $white;
      }
    }
  }

  .newsletter-title {
    @include margin-bottom-last-child-0(58px);
    @include source-sans-pro-semibold(24px, 1.46);

    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-bottom-last-child-0(24px);
      @include source-sans-pro-bold(22px);
      text-align: center;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include margin-bottom-last-child-0(34px);
      @include source-sans-pro-bold(30px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include margin-bottom-last-child-0(46px);
      @include source-sans-pro-bold(40px);
    }
  }

  .convertkit-wrapper {
    @media (min-width: #{$ipad-landscape-width}px) {
      @include margin-left-right(auto);
      display: flex;
      max-width: 600px;
      width: 60vw;
    }
    @media (min-width: #{$desktop-wxga-plus-width}px) {
      max-width: 840px;
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      max-width: 1120px;
    }

    .formkit-form {
      max-width: initial !important;
      width: 100% !important;

      [data-style="clean"] {
        padding: 0 !important;

        [data-element="fields"] {
          &.formkit-fields {
            margin: 0 !important;

            @media (min-width: #{$ipad-landscape-width}px) {
              display: flex;
              flex-wrap: nowrap;
            }

            .formkit-field {
              @include margin-bottom-last-child-0(34px);
              margin: 0;

              @media (min-width: #{$ipad-landscape-width}px) {
                @include margin-bottom-last-child-0(0);
                flex-basis: 70%;
              }

              .formkit-input {
                @include source-sans-pro-regular(18px);
                border-radius: 16px !important;
                border: solid 2px $very-light-violet !important;
                font-weight: normal !important;
                padding: 20px !important;

                @media (min-width: #{$ipad-landscape-width}px) {
                  @include source-sans-pro-regular(16px);
                  border-bottom-right-radius: 0 !important;
                  border-right: none !important;
                  border-top-right-radius: 0 !important;
                  font-weight: normal !important;
                  margin-bottom: 0 !important;
                }

                @media (min-width: #{$desktop-wxga-plus-width}px) {
                  @include source-sans-pro-regular(22px);
                }

                @media (min-width: #{$desktop-uxga-width}px) {
                  @include source-sans-pro-regular(30px);
                }
              }
            }

            .formkit-submit {
              @include padding-left-right(32px);
              @include padding-top-bottom(18px);
              @include source-sans-pro-bold(18px);
              background-color: $very-light-violet !important;
              border-radius: 8px !important;
              color: $white !important;
              display: block;
              font-weight: bold !important;
              text-align: center;
              text-transform: uppercase;

              @media (min-width: #{$ipad-landscape-width}px) {
                @include source-sans-pro-bold(16px);
                border-bottom-left-radius: 0 !important;
                border-top-left-radius: 0 !important;
                flex-basis: 30%;
                margin: 0 !important;
              }

              @media (min-width: #{$desktop-wxga-plus-width}px) {
                @include source-sans-pro-bold(22px);
              }

              @media (min-width: #{$desktop-uxga-width}px) {
                @include source-sans-pro-bold(30px);
              }

              span {
                @include reset-margin-paddding;
                background: $transparent !important;
              }
            }
          }
        }
      }
    }
  }
}
