.modules-content {
  @media (min-width: #{$ipad-landscape-width}px) {
    display: flex;
    flex-wrap: nowrap;
  }

  &.light-theme {
    color: $very-dark-grayish-blue;

    .modules-about {
      background-color: $mostly-white;
    }

    .modules-list {
      background-color: $grayish-cyan;
  
      .modules-accordion {
        .accordion-item {
          &.show {
            .item-head {
              .item-title {
                .icon {
                  svg {
                    fill: $very-dark-grayish-blue;
                  }
                }
              }
            }
          }
  
          &.visible {
            border-bottom: solid 1px $light-grayish-blue;
  
            &:first-child {
              border-top: solid 1px $light-grayish-blue;
            }
          }
  
          .item-head {
            .item-title {
              .icon {
                svg {
                  fill: $light-grayish-blue;
                }
              }
            }
          }
  
          .item-cont {
            .item-cont-wrapper {
              .item-description,
              .item-material {
                color: $light-grayish-blue;
              }
  
              .item-description {
                color: $light-grayish-blue;
              }
            }
          }
        }
      }
    }
  }

  &.dark-theme {
    color: $white;

    .modules-about {
      background-color: $very-dark-grayish-blue;  
    }

    .modules-list {
      background-color: $darker-grayish-blue;
  
      .modules-accordion {
  
        .accordion-item {
          &.show {
            .item-head {
              .item-title {
                .icon {
                  svg {
                    fill: $white;
                  }
                }
              }
            }
          }
  
          &.visible {
            border-bottom: solid 1px $grayish-blue;
  
            &:first-child {
              border-top: solid 1px $grayish-blue;
            }
          }
  
          .item-head {
            .item-title {
              .icon {
                svg {
                  fill: $dark-grayish-blue;
                }
              }
            }
          }
  
          .item-cont {
            .item-cont-wrapper {
              .item-description,
              .item-material {
                color: $grayish-cyan;  
              }
  
              .item-description {
                color: $grayish-cyan;
              }
            }
          }
        }
      }
    }
  }

  .modules-about,
  .modules-list {
    .about-title,
    .list-title {
      @include margin-bottom-last-child-0(20px);
      @include source-sans-pro-bold(30px, 1.17);
    }

    .about-description,
    .list-description {
      @include margin-bottom-last-child-0(38px);
      @include source-sans-pro-regular(18px, 1.67);

      @media (min-width: #{$ipad-landscape-width}px)  {
        @include margin-bottom-last-child-0(20px);
        @include source-sans-pro-regular(16px, 1.2);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-bottom-last-child-0(28px);
        @include source-sans-pro-regular(22px, 1.2);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-bottom-last-child-0(38px);
        @include source-sans-pro-regular(30px, 1.2);
      }

      p {
        & > p {
          @include margin-bottom-last-child-0(18px);

          @media (min-width: #{$ipad-landscape-width}px)  {
            @include margin-bottom-last-child-0(20px);
          }

          @media (min-width: #{$desktop-wxga-plus-width}px) {
            @include margin-bottom-last-child-0(26px);
          }

          @media (min-width: #{$desktop-uxga-width}px) {
            @include margin-bottom-last-child-0(36px);
          }
        }
      }
    }
  }

  .modules-about {
    @include padding-left-right(#{$outer-320}px);
    @include padding-top-bottom(40px, 60px);

    @media (min-width: #{$iphone-8-width}px) {
      @include padding-left-right(#{$outer-414}px);
    }

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-left-right(#{$outer-1024}px);
      @include padding-top-bottom(24px, 26px);
      @include same-min-max-width(62%);
      max-width: 812px;
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-left-right(#{$outer-1440}px);
      @include padding-top-bottom(32px, 36px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-left-right(#{$outer-1600}px);
      @include padding-top-bottom(44px, 48px);
    }

    .about-title {
      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(14px);
        @include source-sans-pro-bold(48px, 1);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-bottom-last-child-0(20px);
        @include source-sans-pro-bold(68px, 1);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-bottom-last-child-0(26px);
        @include source-sans-pro-bold(90px, 1);
      }
    }

    .description-video {
      @include margin-bottom-last-child-0(36px);
      box-shadow: 0 2px 21px 0 rgba($black, 0.5);
      display: block;
      height: auto;
      width: 100%;

      @media (min-width: #{$ipad-landscape-width}px) {
        max-width: 682px;
      }
    }
  }

  .modules-list {
    @include padding-top-bottom(28px, 24px);

    @media (min-width: #{$ipad-landscape-width}px) {
      @include padding-top-bottom(24px, 26px);
      @include same-min-max-width(38%);
    }

    @media (min-width: #{$desktop-wxga-plus-width}px) {
      @include padding-top-bottom(32px, 36px);
    }

    @media (min-width: #{$desktop-uxga-width}px) {
      @include padding-top-bottom(44px, 48px);
    }

    .list-title,
    .list-description {
      @include padding-left-right(#{$outer-320}px);

      @media (min-width: #{$iphone-8-width}px) {
        @include padding-left-right(#{$outer-414}px);
      }
    }

    .list-title {
      @media (min-width: #{$ipad-landscape-width}px) {
        @include margin-bottom-last-child-0(20px);
        @include source-sans-pro-bold(20px);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include margin-bottom-last-child-0(28px);
        @include source-sans-pro-bold(28px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include margin-bottom-last-child-0(36px);
        @include source-sans-pro-bold(36px);
      }
    }

    .list-description {
      .small {
        @include margin-bottom-last-child-0(30px);
        margin-top: 30px;
      }

      .small {
        @include source-sans-pro-regular(18px, 1.67);

        @media (min-width: #{$ipad-landscape-width}px)  {
          @include source-sans-pro-regular(12px);
          margin-top: 8px;
        }

        @media (min-width: #{$desktop-wxga-plus-width}px) {
          @include source-sans-pro-regular(16px);
          margin-top: 10px;
        }

        @media (min-width: #{$desktop-uxga-width}px) {
          @include margin-bottom-last-child-0(36px);
          @include source-sans-pro-regular(22px);
          margin-top: 12px;
        }
      }
    }

    .modules-accordion {
      @media (min-width: #{$ipad-landscape-width}px)  {
        max-height: 50vh;
        overflow-y: scroll;
      }

      &.all-visible {
        .accordion-item {
          &:last-child {
            border-bottom: none;
          }
        }
      }

      .accordion-item {
        border-bottom: none;
        max-height: 0;
        overflow: hidden;
        transition: max-height 800ms;

        @media (min-width: #{$ipad-landscape-width}px)  {
          border-bottom: solid 1px $grayish-blue;
          max-height: initial;
          overflow: initial;
          transition: none;
        }

        &:first-child {
          border-bottom: none;

          @media (min-width: #{$ipad-landscape-width}px)  {
            border-top: solid 1px $grayish-blue;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &.show {
          .item-head {
            .item-title {
              .icon {
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }

          .item-cont {
            max-height: 50em;
            transition: max-height 800ms ease-out;
          }
        }

        &.visible {
          max-height: 50em;
          border-bottom: solid 1px $grayish-blue;

          &:first-child {
            border-top: solid 1px $grayish-blue;
          }

          &.no-transition {
            transition: none;
          }
        }

        .item-head,
        .item-cont {
          .item-title,
          .item-cont-wrapper {
            @include padding-left-right(#{$outer-320}px, #{20 + 18 + $outer-320}px);
            @include padding-top-bottom(16px);

            @media (min-width: #{$iphone-8-width}px) {
              @include padding-left-right(#{$outer-414}px, #{20 + 18 + $outer-414}px);
            }

            @media (min-width: #{$ipad-landscape-width}px) {
              @include padding-top-bottom(16px);
            }

            @media (min-width: #{$desktop-wxga-plus-width}px) {
              @include padding-top-bottom(22px);
            }

            @media (min-width: #{$desktop-uxga-width}px) {
              @include padding-top-bottom(22px);
            }
          }
        }

        .item-head {
          display: block;
          text-align: left;
          width: 100%;

          .item-title {
            @include source-sans-pro-regular(18px, 1.67);
            position: relative;

            @media (min-width: #{$ipad-landscape-width}px) {
              @include source-sans-pro-regular(16px);
            }

            @media (min-width: #{$desktop-wxga-plus-width}px) {
              @include source-sans-pro-regular(22px);
            }

            @media (min-width: #{$desktop-uxga-width}px) {
              @include source-sans-pro-regular(30px);
            }

            .icon {
              @include same-height-width(18px);
              content: '';
              display: block;
              position: absolute;
              right: #{$outer-320}px;
              top: 50%;
              transform: translateY(-50%) rotate(0);
              transition: transform  300ms ease-in;

              @media (min-width: #{$iphone-8-width}px) {
                right: #{$outer-414}px;
              }

              svg {
                display: block;
                height: auto;
                transition: fill 300ms;
                width: 100%;
              }
            }

            b {
              @include source-sans-pro-bold(18px, 1.67);
            }
          }
        }

        .item-cont {
          max-height: 0;
          overflow: hidden;
          transition: max-height 400ms;

          .item-cont-wrapper {
            .item-description,
            .item-material {
              @include margin-bottom-last-child-0(16px);

              @media (min-width: #{$ipad-landscape-width}px) {
                @include source-sans-pro-regular(20px);
              }
  
              @media (min-width: #{$desktop-wxga-plus-width}px) {
                @include source-sans-pro-regular(26px);
              }
  
              @media (min-width: #{$desktop-uxga-width}px) {
                @include margin-bottom-last-child-0(36px);
              }

              p {
                @include margin-bottom-last-child-0(16px);
              }
            }

            .item-description {
              @include source-sans-pro-regular(18px, 1.67);

              @media (min-width: #{$ipad-landscape-width}px) {
                @include source-sans-pro-regular(16px);
              }
  
              @media (min-width: #{$desktop-wxga-plus-width}px) {
                @include source-sans-pro-regular(22px);
              }
  
              @media (min-width: #{$desktop-uxga-width}px) {
                @include source-sans-pro-regular(30px);
              }
            }

            .item-material {
              @include source-sans-pro-regular(18px, 1.67, normal, italic);

              @media (min-width: #{$ipad-landscape-width}px) {
                @include source-sans-pro-regular(14px, normal, normal, italic);
              }
  
              @media (min-width: #{$desktop-wxga-plus-width}px) {
                @include source-sans-pro-regular(18px, normal, normal, italic);
              }
  
              @media (min-width: #{$desktop-uxga-width}px) {
                @include source-sans-pro-regular(24px, normal, normal, italic);
              }
            }
          }
        }
      }
    }
  }

  .show-more-modules {
    @include margin-left-right(auto);
    @include source-sans-pro-regular(18px);
    color: $very-light-violet;
    display: block;
    margin-top: 26px;
    padding-right: 28px;
    position: relative;

    @media (min-width: #{$ipad-landscape-width}px)  {
      display: none;
    }

    &.hide {
      display: none;
    }

    .icon {
      @include same-height-width(18px);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(0);

      @media (min-width: #{$ipad-landscape-width}px)  {
        @include same-height-width(16px);
      }

      @media (min-width: #{$desktop-wxga-plus-width}px) {
        @include same-height-width(22px);
      }

      @media (min-width: #{$desktop-uxga-width}px) {
        @include same-height-width(30px);
      }

      svg {
        fill: $very-light-violet;
        height: auto;
        width: 100%;
      }
    }
  }
}
