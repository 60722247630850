@mixin reset-margin-paddding {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin font-inherit {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@mixin padding-top-bottom($t: initial, $b: $t) {
  padding-top: $t;
  padding-bottom: $b;
}

@mixin padding-left-right($l: initial, $r: $l) {
  padding-left: $l;
  padding-right: $r;
}

@mixin margin-top-bottom($t: initial, $b: $t) {
  margin-top: $t;
  margin-bottom: $b;
}

@mixin margin-left-right($l: initial, $r: $l) {
  margin-left: $l;
  margin-right: $r;
}

@mixin margin-bottom-last-child-0($b: initial) {
  margin-bottom: $b;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin margin-right-last-child-0($r: initial) {
  margin-right: $r;

  &:last-child {
    margin-right: 0;
  }
}

@mixin same-height-width($s) {
  height: $s;
  width: $s;
}

@mixin same-max-height-width($s) {
  max-height: $s;
  max-width: $s;
}

@mixin same-min-max-width($w) {
  max-width: $w;
  min-width: $w;
}

@mixin font-style($s: inherit, $lH: normal, $lS: normal, $st: normal) {
  font-size: $s;
  font-style: $st;
  letter-spacing: $lS;
  line-height: $lH;
}

@mixin source-sans-pro-regular($s: inherit, $lH: normal, $lS: normal, $st: normal) {
  @include font-style($s, $lH, $lS, $st);
  font-family: SourceSansPro;
  font-weight: normal;
}

@mixin source-sans-pro-semibold($s: inherit, $lH: normal, $lS: normal, $st: normal) {
  @include font-style($s, $lH, $lS, $st);
  font-family: SourceSansPro;
  font-weight: 600;
}

@mixin source-sans-pro-bold($s: inherit, $lH: normal, $lS: normal, $st: normal) {
  @include font-style($s, $lH, $lS, $st);
  font-family: SourceSansPro;
  font-weight: bold;
}

@mixin din-condensed-bold($s: inherit, $lH: normal, $lS: normal, $st: normal) {
  @include font-style($s, $lH, $lS, $st);
  font-family: DINCondensed;
  font-weight: bold;
}
